@import '../../../css/variables';

.home_hero_element {
  position: relative;
  background-color: $color-primary;
  color: $color-tertiary;
  overflow: hidden;
  margin-bottom: 5rem;

  .container, .row, .col {
    pointer-events: none;
  }

  &__bg {
    position: absolute;
    height: 140%;
    top: -20%;
    width: 100%;
    transition: ease;
    pointer-events: initial;
    @include bg-pos--contain-repeat(100%);
    @include pattern($pattern-1, 0.7);
  }

  &__image {
    @include bg-abs-cover;
    opacity: 0.7;
  }

  &__title {
    @include h1--huge;
    text-align: center;
    margin: 20rem 0 4rem;
    pointer-events: initial;

    @media(max-width: 768px){
      margin-top: 200px;
      font-size: 4rem;
    }
    
    @include font-inherit;
    @include no-spacing;
  }

  &__intro {
    margin-bottom: 4rem;
    transition: ease;
    color: #fff;
    pointer-events: initial;
  }

}
